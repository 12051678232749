<template>
<div class="section">
  <div class="columns is-centered" :class="{ 'px-0 pt-0': isMobile, 'px-6 py-5': isDesktop }">
    <div class="column is-12">
      <Markdown :text="text" />
    </div>
  </div>
</div>
</template>

<script>
import Markdown from '@/components/common/Markdown.vue'
import VueBreakpointMixin from 'vue-breakpoint-mixin'

export default {
  name: 'Fees',
  components: {
    Markdown,
  },
  mixins: [VueBreakpointMixin],
  mounted () {
    document.querySelector('table').classList = 'table is-narrow is-fullwidth'
  },
  data() {
    return {
      text: `
## Тарифи

#### Тук можете да намерите информация за таксите, с които Блокчейн Тех ЕООД предоставя своите услуги

##### _Моля запознайте се внимателно с последващата информация. Фирмата Блокчейн Тех ЕООД, оперираща под бранда CryproDesk.bg обслужва клиенти посредством различни методи за покупка/продажба на криптовалути._

|                                                                                                                            | **Покупка**                          | **Продажба**                         |
|----------------------------------------------------------------------------------------------------------------------------|--------------------------------------|--------------------------------------|
| **Уебсайт** ([www.cryptodesk.bg](https://cryptodesk.bg))                                                                   |                                      |                                      |
| Банков превод                                                                                                              | **2,5%**                             | **2,5%**                             |
| EasyPay каса                                                                                                               | **3,5%** (_2,5% + 1% EasyPay такса_) | **4,5%** (_2,5% + 2% EasyPay такса_) |
| **CryptoDesk ATM** ([Криптомати](https://atm.cryptodesk.bg/))                                                              |                                      |                                      |
| [София](https://coinatmradar.com/bitcoin_atm/61681/bitcoin-atm-general-bytes-sofia-south-mall/)                            | **2,5%**                             | **5%**                               |
| [Пловдив](https://coinatmradar.com/bitcoin_atm/27633/bitcoin-atm-general-bytes-plovdiv-kapana-kapana/)                     | **2,5%**                             | **5%**                               |
| [Варна](https://coinatmradar.com/bitcoin_atm/14514/bitcoin-atm-general-bytes-varna-delta-planet-mall-delta-planet/)        | **2,5%**                             | **5%**                               |
| [Бургас](https://coinatmradar.com/bitcoin_atm/15132/bitcoin-atm-general-bytes-burgas-pirgos-style-shopping-center-pirgos/) | **2,5%**                             | **5%**                               |
| **Bitcoin Teller** (Крипто Касиер) *                                                                                       |                                      |                                      |
| [София](https://coinatmradar.com/bitcoin_teller/13490/bitcoin-teller-sofia-tsentralna-gara-central-station/)               | **6%**                               | **6%**                               |
| [Велико Търново](https://coinatmradar.com/bitcoin_teller/13492/bitcoin-teller-veliko-tarnovo-avtogara-yug/)                | **6%**                               | **6%**                               |

<span style="color:red">_* Обслужването чрез фирма партньор (Bitcoin Teller) от посочените локации става единствено след предварителна комуникация с екипа на CryptoDesk.bg и създаване на потребителски профил на нашия сайт!_</span>
      `,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
